import Vue from "vue";
import "vant/lib/index.css";

import {
  Popup,
  Toast,
  Dialog,
  CountDown,
  Cascader,
  Progress,
  Field,
  Button,
  Lazyload
} from "vant";

Vue.use(Popup)
  .use(Toast)
  .use(Dialog)
  .use(CountDown)
  .use(Cascader)
  .use(Progress)
  .use(Field)
  .use(Button)
  .use(Lazyload)
