import axios from "axios";
import qs from "qs";
import { Dialog } from 'vant'
import router from '@/router'


const AxiosInstance = axios.create({
  //withCredentials: true,
  timeout: 5000,
  baseURL: 'https://qccv7.koobeen.com/crowd/v1/',
  headers: {},
  transformRequest: [
    (data) => {
      data = qs.stringify(data);
      return data;
    }
  ],
  transformResponse: [
    data => {
      if (!data) {
        return data;
      }
      data = JSON.parse(data);
      return data;
    }
  ]
});

export default {
  fetch(url, data, method = "get", params, headers) {
    const options = {
      method,
      url,
      data,
      headers,
      params
    };
    return this.axiosCustomize(options);
  },
  axiosCustomize(options) {
    return new Promise((resolve, reject) => {
      /* eslint-disable */
      AxiosInstance(options)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
      /* eslint-enable */
    });
  },
  get(url, params = {}, headers = {}, boolen = false) {
    let header = {
      token: boolen ? window.localStorage.getItem('token') : undefined,
      ...headers
    }
    return this.fetch(url, null, "get", params, header);
  },
  post(url, data = {}, headers = {}) {
    const header = {
      ...headers,
      token: window.localStorage.getItem('token')
    }
    return this.fetch(url, data, "post", null, header);
  }
};

AxiosInstance.interceptors.response.use(res => {
  if (res) {
    if (res.data.code === -2) {
      Dialog({
        title: '提示',
        message: res.data.message,
      })
    }
    if (res.data.code === -3) {
      let id = window.localStorage.getItem('id') || 0
      router.push({
        name: 'index',
        query: { id }
      })
    }
    if (res.data.code === 2) {
      window.localStorage.setItem('token', res.data.token)
      window.localStorage.setItem('user', JSON.stringify(res.data.user))
      window.location.reload()
    }
    return res
  }
})
