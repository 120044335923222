<template>
  <div class="index">
    <div>
      <div class="nav">
        <div class="bg" :style="{ background: 'url(' + items.headimgurl + ') #999 no-repeat center center', backgroundSize: '180% auto' }"></div>
        <div class="user">
          <div class="img">
            <img v-lazy="items.headimgurl" />
          </div>
          <div class="info">
            <p>{{ items.nickname }}也想成为"最美妈妈"</p>
            <h5>系统账号：{{ items.phone }}</h5>
            <span>{{ items.binding_time }}发起</span>
          </div>
          <van-button color="#ff4400" v-if="items.isShare" class="n-btn" type="primary" size="small" @click="sharePopup = true">点击分享</van-button>
        </div>
      </div>
      <div class="time">
        <span>距离完成梦想结束：</span><i v-if="computeTime < 1">已结束</i><van-count-down v-if="computeTime > 1" :time="computeTime" format="DD 天 HH 时 mm 分 ss 秒" />
      </div>
      <div class="n-info">
        <p>{{ items.project_head }}</p>
      </div>
      <div class="i-info" v-html="items.centent"></div>
      <ul class="ul">
        <li>
          <h2>{{ items.total_price }}积分</h2>
          <p>妈妈梦想</p>
        </li>
        <li>
          <h2>{{ items.money }}积分</h2>
          <p>已获得</p>
        </li>
        <li>
          <h2>{{ items.number }}人</h2>
          <p>支持人数</p>
        </li>
      </ul>
      <!-- 进度条  -->
      <div class="pr">
        <CommentProgress :degree="numberIndex"> </CommentProgress>
      </div>
      <div class="brief" :class="{'height': showDetails }">
        <div class="text" v-html="items.detail_note">
        </div>
        <div class="text-show" v-if="false">
          <span>查看详情</span>
        </div>
      </div>
    </div>

    <!-- 支持者（0）  -->
    <div class="stand">
      <div class="title">
        支持者（{{ items.number }}）
      </div>
      <div class="content">
        <div class="list" v-for="(item, index) in items.data" :key="index">
          <div class="l-box">
            <div class="img">
              <img v-lazy="item.headimgurl"  alt="" />
            </div>
            <div class="text">
              {{ item.nickname }}
            </div>
            <div class="product">
              {{ item.money }}积分
            </div>
          </div>
          <div class="content">
            <p>{{ item.content }}</p>
          </div>
          <div class="name">
            {{ item.name }}
          </div>
          <div class="l-time">
            {{ item.add_time }}
          </div>
        </div>
      </div>
    </div>

    <div class="btn">
      <router-link class="link" to="/product" tag="div" exact>
        支持 Ta 的梦想
      </router-link>
      <router-link class="link" to="/form" tag="div" exact>
        报名"最美妈妈"
      </router-link>
    </div>


    <!--  分享弹窗  -->
    <van-popup class="popup" v-model="sharePopup" round>
      <div class="s-content">
        <div class="s-title">提示</div>
        <div class="s-info">复制我的分享链接</div>
        <van-button
            type="primary"
            class="share-btn"
            id="share-btn"
            @click="onShareUrl"
            :data-clipboard-text="shareUrl"
        >确认复制</van-button>
      </div>
    </van-popup>

  </div>
</template>

<script>
import CommentProgress from "@/components/comment-progress";
import axios from '@/config/axios'
import Clipboard from 'clipboard'
import wx from 'weixin-js-sdk'

export default {
  name: "index",
  components: {
    CommentProgress
  },
  data() {
    return {
      title: "index",
      items: {},
      showDetails: false,
      sharePopup: false,
      shareUrl: '无链接',
      config: {},
      configinfo: {}
    };
  },
  computed: {
    numberIndex () {
      return this.items.percentage ? Number(this.items.percentage.replace('%', '')) : 0
    },
    computeTime () {
      let endTime = this.items.end_time
      if (!endTime) return 100
      endTime = endTime + ''
      endTime = endTime.replace(/-/g, '/')
      endTime = new Date(endTime).getTime()
      return endTime - this.items.curTime * 1000 || 0
    }
  },
  created() {
    this.setId()
  },
  methods: {
    setId () {
      const uid = this.$route.query.id || window.localStorage.getItem('id') || null
      window.localStorage.setItem('id', uid)
      if(uid==null) return;
      this.init()
      this.getUserInfo()
    },
    init () {
      let code = this.$route.query.code || window.localStorage.getItem('code')
      console.log(this.$route.query);
      if (code) return
      if (localStorage.getItem('token')) return
      axios.get('code')
        .then(res => {
          location.href = res.data
        })
    },
    getUserInfo () {
      let code = this.$route.query.code || window.localStorage.getItem('code')
      if (window.localStorage.getItem('token')) {
        this.getGroup(code)
        return
      }

      window.localStorage.setItem('code', code)
      axios.get('wechat', { code } , '', false)
        .then(res => {
          if (res.code !== 1) {
            this.$toast.fail(res.message)
            window.localStorage.removeItem('code')
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('user')
            return
          }
          this.$toast.success(res.message)
          window.localStorage.setItem('token', res.data.token)
          window.localStorage.setItem('user', JSON.stringify(res.data.user))
          this.getGroup(code)
        })
    },
    getGroup (code) {
      let url = location.href
      let uid = window.localStorage.getItem('id')
      let that = this

      let data = {
        url,
        code,
        uid,
        state: 123
      }
      axios.get('jsapiTicket', data, '', true)
        .then(res => {
          if(res.code === -1 || res.code === 999) {
            window.localStorage.removeItem('code')
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('user')
            return
          }

          if (res.url) {
            window.location.href = res.url
            return
          }
          this.items = res.data
          if (this.items.isShare) {
            this.shareUrl = this.items.link
          }

				that.config.nonceStr = this.items.noncestr
				that.config.signature = this.items.signature
				that.config.timestamp = this.items.timestamp
				that.configinfo.title = this.items.title
				that.configinfo.desc = this.items.desc
				that.configinfo.link = this.items.link
				that.configinfo.shareimg = this.items.shareimg
				that.config.appId = this.items.appid
				that.config.debug = false
				that.config.jsApiList = [
					'checkJsApi',
					'updateAppMessageShareData',
					'updateTimelineShareData',
				]
				wx.config(that.config);
					
				wx.ready(() => {console.log('ready');
					wx.checkJsApi({
						jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
						success: function(res) {
							console.log(res);
						}
					});

		wx.updateAppMessageShareData({
			title: that.configinfo.title,
			desc: that.configinfo.desc,
			link: that.configinfo.link,
			imgUrl: that.configinfo.shareimg,
			success: function() {
			}
		});

		wx.updateTimelineShareData({
			title: that.configinfo.title,
			link: that.configinfo.link,
			imgUrl: that.configinfo.shareimg,
			success: function() {
			}
		});
					
				});


        })
    },
    onShareUrl () {
      this.sharePopup = false
      const clipboard = new Clipboard('#share-btn')
      clipboard
          .on('success', () => {
            this.$toast('复制成功')
          })
          .on('error', (e) => {
            this.$toast('复制失败，请刷新页面重试')
            console.log(e)
          })
    }
  }
}
</script>

<style scoped lang="scss">
.index {
  padding-bottom: 98px;
  .i-info {
    padding: 20px 30px;
    color: #666666;
    font-size: 28px;
    line-height: 52px;
    background: #ffffff;
  }
  .nav {
    padding: 20px 30px;
    position: relative;
    overflow: hidden;
    .bg {
      background: #ffffff;
      background-size: 180% auto;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      filter: blur(30px);
    }
    .user {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 5;
      color: #ffffff;
      .img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        min-width: 100px;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .info {
        margin-left: 30px;
        font-size: 28px;
        line-height: 48px;
        flex: 1 0 0;
        h5 {
          font-weight: normal;
        }
      }
      .btn {

      }
    }
  }
  .n-info {
    text-align: center;
    padding: 30px;
    p {
      font-size: 26px;
      line-height: 36px;
      color: #666666;
    }
  }
  .time {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    background: #ffffff;
    line-height: 80px;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    .van-count-down {
      color: #f00;
      font-size: 26px;
    }
    i {
      color: #ff0000;
    }
  }

  .ul {
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #f5f5f5;
    border-top: 1px solid #f5f5f5;
    background: #ffffff;
    li {
      flex: 1 0 0;
      position: relative;
      padding: 20px 0;
      &:last-child {
        &:after {
          content: "";
          display: none;
        }
      }
      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        height: 40px;
        margin-top: -20px;
        width: 1px;
        background: #f5f5f5;
      }
      h2 {
        font-size: 26px;
        font-weight: normal;
        line-height: 48px;
        color: #ff4949;
      }
      p {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
  .pr {
    padding: 40px 30px;
    background: #ffffff;
  }
  .brief {
    padding: 20px 20px;
    font-size: 26px;
    line-height: 40px;
    background: #ffffff;
    margin: 20px 0;
    position: relative;
    min-height: 150px;
    overflow: hidden;
  }
  .text-show {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: 120px;
    background: linear-gradient(to top, #fff, #fff, rgba(255, 255, 255, 0));
    span {
      font-size: 28px;
      position: relative;
      z-index: 5;
    }
  }

  .stand {
    .title {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 99;
      line-height: 80px;
      text-align: center;
      font-size: 32px;
      background: #ffffff;
      color: #ff4400;
      border-bottom: 4px solid #ff4400;
    }
    .content {
      .list {
        padding: 20px 30px;
        background: #ffffff;
        border-bottom: 1px solid #f5f5f5;
        &:last-child {
          border-bottom: 0 none;
        }
        .l-box {
          display: flex;
          align-items: center;
          .img {
            margin-right: 30px;
            min-width: 80px;
            width: 80px;
            height: 80px;
            border-radius: 40px;
            overflow: hidden;
            img {
              vertical-align: top;
              object-fit: contain;
            }
          }
          .text {
            flex: 1;
            font-size: 32px;
          }
          .product {
            font-size: 30px;
            color: #ff4400;
          }
        }
        .content {
          font-size: 28px;
          line-height: 36px;
          padding-left: 110px;
          margin-bottom: 10px;
          p {
            word-break: break-all;
          }
        }
        .name {
          font-size: 28px;
          padding-left: 110px;
          line-height: 46px;
          color: #666666;
        }
        .l-time {
          font-size: 28px;
          line-height: 46px;
          color: #999999;
          padding-left: 110px;

        }
      }
    }
  }
}

.btn {
  display: flex;
  z-index: 1000;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .link {
    flex: 1 0 0;
    font-size: 28px;
    text-align: center;
    line-height: 96px;
    color: #ffffff;
    &:nth-child(1) {
      background: #ff4400;
    }
    &:nth-child(2) {
      background: #999999;
    }
  }
}

.popup {
  .s-content {
    text-align: center;
    padding: 20px 30px 30px;
    width: 500px;
    .s-title {
      line-height: 100px;
      font-size: 36px;
      color: #ff4400;
    }
    .s-info {
      font-size: 32px;
      color: #323233;
      line-height: 42px;
    }
    .share-btn {
      width: 100%;
      height: 80px;
      border-radius: 40px;
      background: #ff4400;
      border-color: #ff4400;
      color: #ffffff;
      font-size: 30px;
    }
  }
}
</style>
