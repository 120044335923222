import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/index",
    name: "index",
    component: Home
  },
  {
    path: "/form",
    name: "formPage",
    component: () => import("../views/form"),
  },
  {
    path: "/product",
    name: "product",
    component: () => import("../views/product"),
    meta: {}
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path !== '/index') {
    if (!window.localStorage.getItem('token') || !window.localStorage.getItem('code')) {
      window.localStorage.removeItem('code')
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')
	  if(to.query.code.length>0) {
		next('/index?code='+to.query.code)		
	  } else {
		next('/index')
		}
    }
  }
  next()
})

export default router;
