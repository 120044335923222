<template>
  <div class="progress">
    <div
      class="box"
      :style="{'--data-height': height, '--data-bg': bgColor }"
    >
      <div class="line" :style="'left:' + degree + '%'"></div>
      <div class="position" :style="'left:' + degree + '%'">
        <slot name="middle">
          <span class="text">{{ degree }}%</span>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentProgress",
  props: {
    height: {
      type: Number,
      default: 0.4
    },
    bgColor: {
      type: String,
      default: "#ff4400"
    },
    degree: {
      type: Number,
      default: 60
    }
  },
  data() {
    return {
      title: "progress"
    };
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style scoped lang="scss">
.progress {
  .box {
    font-size: 0;
    width: 100%;
    position: relative;
    height: calc(var(--data-height) * 20px);
    background: var(--data-bg);
    border-radius: calc(var(--data-height) * 20px / 2);
    .line {
      position: absolute;
      z-index: 5;
      overflow: hidden;
      top: 0;
      bottom: 0;
      right: 0;
      border-radius: calc(var(--data-height) * 20px / 2);
      background: #f5f5f5;
      transition: left ease-out 2s;
    }
    .position {
      position: absolute;
      z-index: 6;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 0 10px;
      background: var(--data-bg);
      border-radius: 15px;
      line-height: 30px;
      font-size: 24px;
      color: #ffffff;
      transition: left ease-out 2s;
    }
    .text {
      font-size: 24px;
    }
  }
}
</style>
